import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "../styles/main.css"
import { Helmet } from "react-helmet"



const IndexPage = () => {
    return (

    <main className="hotels">
    <Helmet>  
        <title>Martin Zerr – Fünf Sterne</title>
    </Helmet> 
        <a className="back" href="/">zurück</a>



        <div className="hotels_wrapper">

        <h1>Fünf Sterne</h1>

        <div className="hotel adlon">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_adlon_layout_0000_adlon_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_adlon_layout_0001_adlon_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_adlon_layout_0002_adlon_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_adlon_layout_0003_adlon_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_adlon_layout_0004_adlon_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_rome_layout_0000_rome_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_rome_layout_0001_rome_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_rome_layout_0002_rome_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_rome_layout_0003_rome_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_rome_layout_0004_rome_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_hyatt_layout_0000_hyatt_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_hyatt_layout_0001_hyatt_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_hyatt_layout_0002_hyatt_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_hyatt_layout_0003_hyatt_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_hyatt_layout_0004_hyatt_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_regnet_layout_0000_regent_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_regnet_layout_0001_regent_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_regnet_layout_0002_regent_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_regnet_layout_0003_regent_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_regnet_layout_0004_regent_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_bristol_layout_0000_bristol_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_bristol_layout_0001_bristol_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_bristol_layout_0002_bristol_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_bristol_layout_0003_bristol_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_bristol_layout_0004_bristol_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_conti_layout_0000_conti_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_conti_layout_0001_conti_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_conti_layout_0002_conti_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_conti_layout_0003_conti_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_conti_layout_0004_conti_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_mandala_layout_0000_mandala_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_mandala_layout_0001_mandala_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_mandala_layout_0002_mandala_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_mandala_layout_0003_mandala_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_mandala_layout_0004_mandala_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_ritz_layout_0000_ritz_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_ritz_layout_0001_ritz_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_ritz_layout_0002_ritz_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_ritz_layout_0003_ritz_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_ritz_layout_0004_ritz_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_waldorf_layout_0000_waldorf_01.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_waldorf_layout_0001_waldorf_02.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_waldorf_layout_0002_waldorf_03.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_waldorf_layout_0003_waldorf_04.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/Martin_Zerr_Tecco_PM_225_230g_waldorf_layout_0004_waldorf_05.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        <div className="hotel ">
            <Zoom><StaticImage src="../images/20220124_162503.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/20220126_153636.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/20220126_153704.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/20220128_093756.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
            <Zoom><StaticImage src="../images/20220128_093435-1.jpeg" alt="" placeholder="blurred" quality={100}/></Zoom>
        </div>

        </div> 


        <a className="kontakt" href="/kontakt">Kontakt</a>



    </main>

    )
}

export default IndexPage